import jquery from "jquery";
window.$ = window.jQuery = jquery;

if ($(".labzisa")[0]) {
  applyCss();
};

$(window).on('resize', function() {
  if ($(".labzisa")[0]) {
    applyCss();
  };
});

function applyCss() {
  $("#lab").css({
    'background-position': '100% calc(100% - ' + $('footer').outerHeight()  + 'px)',
    'background-size': 'contain',
  });
}